.card--servicoPrincipal {
  width: 100%;
  height: 130px;
  border-radius: 10px;
  background: var(--text-color-light);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 15px;
  position: relative;
  box-sizing: border-box;
  z-index: 4 !important;
  transition: transform 0.1s ease-in-out;
}

.card--servicoPrincipal:hover {
  transform: scaleY(1.2) translateY(10px);
  cursor: pointer;
  border-radius: 5px;
}

.card--servicoPrincipal.disabled {
  background: var(--offwhite-light);
  cursor: auto;
}

@media (max-width: 768px) {
  .card--servicoPrincipal {
    margin-bottom: 25px;
    /* padding: 15px 25px; */
    transform: scaleY(1.2) translateY(10px);
    cursor: pointer;
    border-radius: 5px;
  }

  .card--servicoPrincipal--title,
  .card--servicoPrincipal--subtitle,
  .card--servicoPrincipal--subtitle.big-title,
  .card--servicoPrincipal--subtitle p,
  .card--servicoPrincipal--subtitle.big-title p,
  .card--servicoPrincipal--button {
    transform: scaleY(0.8);
  }

  .card--servicoPrincipal--subtitle {
    display: block !important;
    transform: translateY(15px);
    max-width: 75%;
  }
}

.card--servicoPrincipal .card--servicoPrincipal--title {
  color: var(--secondary-color);
  font-size: 1.4rem;
  font-weight: 700;
  position: relative;
  display: flex;
  justify-content: flex-start;
  transition: transform 0.1s ease-in-out;
}

.card--servicoPrincipal:hover .card--servicoPrincipal--title,
.card--servicoPrincipal:hover .card--servicoPrincipal--subtitle,
.card--servicoPrincipal:hover .card--servicoPrincipal--subtitle.big-title,
.card--servicoPrincipal:hover .card--servicoPrincipal--subtitle p,
.card--servicoPrincipal:hover .card--servicoPrincipal--subtitle.big-title p,
.card--servicoPrincipal:hover .card--servicoPrincipal--button {
  transform: scaleY(0.8);
}

.card--servicoPrincipal .card--servicoPrincipal--subtitle {
  display: none;
  margin: -10px;
  padding: 0 10px;
  transition: all 0.1s ease-in-out;
}

.card--servicoPrincipal:hover .card--servicoPrincipal--subtitle {
  display: block;
  transform: translateY(15px);
  max-width: 75%;
}

.card--servicoPrincipal .card--servicoPrincipal--subtitle .separator {
  display: block;
  width: 60%;
  height: 1px;
  border-top: 2px solid var(--secondary-color);
  margin-top: -15px;
  padding-top: 5px;
}

.card--servicoPrincipal .card--servicoPrincipal--subtitle p {
  font-size: 15px !important;
  font-weight: 400;
  padding: 0;
  opacity: 0.8;
  line-height: 20px;
}

.card--servicoPrincipal .card--servicoPrincipal--title.big-title,
.card--servicoPrincipal .card--servicoPrincipal--subtitle p {
  font-size: 1.4rem;
}

@media (max-width: 1286px) {
  .card--servicoPrincipal .card--servicoPrincipal--title.big-title,
  .card--servicoPrincipal .card--servicoPrincipal--subtitle p {
    /* font-size: 16px; */
    font-size: 1.4rem;
  }
}

@media (max-width: 1199px) {
  .card--servicoPrincipal .card--servicoPrincipal--title {
    /* font-size: 16px; */
    font-size: 1.4rem;
  }

  .card--servicoPrincipal .card--servicoPrincipal--title.big-title,
  .card--servicoPrincipal .card--servicoPrincipal--subtitle p {
    /* font-size: 14px; */
    font-size: 1.4rem;
  }
}

@media (max-width: 991px) {
  .card--servicoPrincipal .card--servicoPrincipal--title {
    /* font-size: 14px; */
    font-size: 1.4rem;
  }

  .card--servicoPrincipal .card--servicoPrincipal--title.big-title,
  .card--servicoPrincipal .card--servicoPrincipal--subtitle p {
    /* font-size: 10px; */
    font-size: 1.4rem;
  }
}

@media (max-width: 768px) {
  .card--servicoPrincipal .card--servicoPrincipal--title {
    /* font-size: 18px; */
    font-size: 1.4rem;
  }

  .card--servicoPrincipal .card--servicoPrincipal--title.big-title,
  .card--servicoPrincipal .card--servicoPrincipal--subtitle p {
    /* font-size: 16px; */
    font-size: 1.4rem;
  }
}

/* .card--servicoPrincipal .card--servicoPrincipal--title:after {
  content: "";
  position: absolute;
  bottom: -15px;
  width: 10px;
  height: 3px;
  background: var(--secondary-color);
} */

.card--servicoPrincipal .card--servicoPrincipal--button {
  width: fit-content;
  position: absolute;
  bottom: 0;
  padding-bottom: 15px;
  color: var(--secondary-color);
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: 700;
  right: 5%;
}
