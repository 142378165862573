.denuncia--container__wrapper {
  max-width: 1420px;
  width: 100%;
  justify-content: flex-start;
}

.denuncia--container__wrapper .denuncia--content {
  width: 100%;
  display: flex;
  margin-top: 50px;
  justify-content: center;
}

@media (max-width: 768px) {
  .denuncia--container__wrapper .denuncia--content {
    flex-direction: column;
    margin-top: 15px;
  }
}

.denuncia--container__wrapper .denuncia--content .denuncia__texto {
  flex: 1;
  padding-right: 15px;
}

@media (max-width: 768px) {
  .denuncia--container__wrapper .denuncia--content .denuncia__texto {
    display: flex;
    flex-direction: column;
    padding-right: 0px;
    margin-bottom: 30px;
  }
}

.denuncia--container__wrapper .denuncia--content .denuncia__texto p {
  width: 100%;
  font-size: 16px;
  text-align: justify;
}

.external-link {
  color: var(--secondary-color);
  transition: color 300ms ease-in-out;
}

.external-link:hover {
  color: var(--primary-color-medium);
}

.falabr-info {
  margin-top: 30px;
}

.falabr-link {
  color: var(--secondary-color);
}

.falabr-link:hover {
  color: var(--text-color-medium);
}
