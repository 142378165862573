footer {
  background: var(--offwhite-light);
  padding: 60px 15px 20px;
  margin-top: 100px;
  position: absolute;
  /* bottom: 0; */
  width: 100%;
}

footer .footer-info-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  margin: 0 auto;
}

@media (max-width: 991px) {
  footer .footer-info-wrapper {
    margin-top: 50px;
  }
}

@media (max-width: 768px) {
  footer .footer-info-wrapper {
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr;
    gap: 0px;
    margin: 0;
  }
}

footer .footer-info-wrapper .footer-info {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-width: 100%;
}
