.container .title__publications,
.container .title__publications.elections {
  width: 100%;
  margin-top: 35px;
}

.container .title__publications strong {
  padding: 15px;
  width: fit-content;
  border-radius: 10px;
  background: var(--secondary-color);
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  color: var(--text-color-light);
}

.container .title__publications.elections strong {
  background: none;
  padding: 15px 0;
  width: fit-content;
  border-radius: 10px;
  font-size: 20px;
  text-align: left;
  font-weight: normal;
  color: var(--secondary-color);
  text-decoration: underline;
}

.container .title__publications .strong_container {
  padding: 15px;
  width: fit-content;
  border-radius: 10px;
  background: var(--secondary-color);
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  color: var(--text-color-dark);
}

.page_banner_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page_banner {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  border-style: none;
}
