.bg-home .agendaHome {
  width: 100%;
  height: 100%;
  max-height: 400px;
  background: var(--text-color-light);
  border-radius: 5px;
  padding: 15px;
  box-sizing: border-box;
  z-index: 3;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  position: relative;
}

@media (max-width: 768px) {
  .bg-home .agendaHome {
    margin-bottom: 10px;
    height: 230px;
    margin-top: 8vh;
  }
}

@media (max-width: 425px) {
  .bg-home .agendaHome {
    margin-top: -10px;
  }
}

@media (max-width: 375px) {
  .bg-home .agendaHome {
    margin-top: -25px;
  }
}

@media (max-width: 320px) {
  .bg-home .agendaHome {
    margin-top: -50px;
  }
}

.bg-home .agendaHome .agendaHome__title {
  width: 100%;
  height: 12%;
  padding: 10px 0px;
  border-bottom: 2px solid var(--secondary-color);
}

.bg-home .agendaHome .agendaHome__title p {
  font-weight: 700;
  font-size: 20px;
  line-height: 15px;
}

@media (max-width: 767px) {
  .bg-home .agendaHome .agendaHome__title p {
    margin-top: -10px;
  }
}

.bg-home .agendaHome .agendaHome__lista {
  width: 100%;
  height: 70%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

@media (max-width: 767px) {
  .bg-home .agendaHome .agendaHome__lista {
    height: 60%;
  }
}

.bg-home .agendaHome .agendaHome__lista .empty_agenda {
  margin-top: 20px;
  text-align: center;
}

.bg-home .agendaHome .agendaHome__lista::-webkit-scrollbar {
  display: none;
}

.bg-home .agendaHome .button {
  display: flex;
  justify-content: center;
  background: var(--secondary-color);
  width: calc(100% - 30px);
  border-radius: 50px;
  border: none;
  padding: 10px 0px;
  color: var(--text-color-light);
  font-weight: 700;
  font-size: 14px;
  margin-top: 20px;
  position: absolute;
  bottom: 20px;
}

.bg-home .agendaHome .button.disabled {
  background-color: var(--offwhite-dark);
}

@media (max-width: 767px) {
  .bg-home .agendaHome .button {
    bottom: 10px;
  }
}
