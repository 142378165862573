.bg-home .agendaHome .agendaHome__lista .agendaHome__block {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  /* margin-bottom: 15px; */
  border-bottom: 1px solid var(--offwhite-medium);
}

.bg-home .agendaHome .agendaHome__lista .agendaHome__block:first-child {
  margin-top: 15px;
}

.bg-home .agendaHome .agendaHome__lista .agendaHome__block .agendaHome__data {
  display: flex;
  flex: 1;
}

.bg-home .agendaHome .agendaHome__lista .agendaHome__block .agendaHome__data p {
  font-size: 14px;
  text-align: center;
  width: 100%;
  font-weight: 700;
  margin: 0;
}

.bg-home
  .agendaHome
  .agendaHome__lista
  .agendaHome__block
  .agendaHome__descricao {
  display: flex;
  flex: 4;
}

.bg-home
  .agendaHome
  .agendaHome__lista
  .agendaHome__block
  .agendaHome__descricao
  p {
  font-size: 12px;
  margin: 0;
  margin-left: 10px;
}
