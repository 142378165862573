svg {
  border-radius: 20px;
  width: 100%;
  height: auto;
  margin: auto;
}

@media screen and (max-width: 768px) {
  svg {
    width: 100%;
    margin: 0;
  }
}

.city {
  transition: fill 0.1s ease;
}

.city:hover {
  fill: var(--primary-color-darker);
  cursor: pointer;
}

.vector {
  transition: fill 0.1s ease;
}

.city:hover .vector,
.vector.active {
  fill: var(--primary-color-darker);
}

.title {
  transition: fill 0.1s ease;
}

.city:hover .title,
.title.active {
  fill: var(--text-color-light);
}
