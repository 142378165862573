#manual_fiscalizacao strong {
  color: var(--secondary-color) !important;
}

.licitacao__doc {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.licitacao__doc .licitacao_doc_header {
  width: 100%;
}

.licitacao__doc img {
  max-width: 640px;
  width: 100%;
}

.licitacao__doc .licitacao_doc_block {
  display: flex;
}

@media (max-width: 768px) {
  .licitacao__doc .licitacao_doc_block {
    flex-direction: column;
    margin-left: 15px;
  }
}

.licitacao__doc .licitacao_doc_block .licitacao-left {
  flex: 1;
}

.licitacao__doc .licitacao_doc_block .licitacao-left ul {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.licitacao__doc .licitacao_doc_block .licitacao-left ul li {
  list-style: none;
  margin-top: 5px;
  position: relative;
}

.licitacao__doc .licitacao_doc_block .licitacao-left ul li:before {
  content: "";
  width: 4px;
  height: 100%;
  position: absolute;
  left: -15px;
  background: var(--secondary-color);
  display: flex;
  transition: ease-in-out 220ms;
}

.licitacao__doc .licitacao_doc_block .licitacao-right {
  flex: 1;
}

.licitacao__doc .licitacao_doc_block .licitacao-right ul {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.licitacao__doc .licitacao_doc_block .licitacao-right ul li {
  list-style: none;
  margin-top: 5px;
  position: relative;
}

.licitacao__doc .licitacao_doc_block .licitacao-right ul li:before {
  content: "";
  width: 4px;
  height: 100%;
  position: absolute;
  left: -15px;
  background: var(--secondary-color);
  display: flex;
  transition: ease-in-out 220ms;
}
