.highlightsContainer {
  background-color: var(--text-color-light);
}

.mostSearchedContainer {
  background-color: var(--primary-color-dark);
}

.newsBlockContainer {
  background-color: var(--text-color-light);
}

.linksDisplayContainer {
  background-color: var(--primary-color-dark);
}

.officesLocationsContainer {
  background-color: var(--primary-color-darker);
}

.publicationsBlockAndMagazinesListContainer {
  background-color: var(--primary-color-lightest);
  margin-bottom: -100px;
}

.bg-home {
  width: 100%;
  background: var(--primary-color-darker);
  padding: 30px 0px;
  position: relative;
  z-index: 0;
}

.bg-home:after {
  margin-bottom: -3px;
  content: "";
  width: 100%;
  position: absolute;
  bottom: 0;
  background: var(--text-color-light);
  height: 65px;
  z-index: -1;
}

@media (max-width: 768px) {
  .bg-home:after {
    background: var(--primary-color-darker);
    height: 80px;
    z-index: -1;
  }
}
