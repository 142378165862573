.navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
  font-weight: 700;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--text-color-light) !important;
}

.navbar .navbar-collapse .navbar-nav .nav-item .nav-link span {
  font-size: 30px;
  margin-right: 5px;
}

.navbar .navbar-collapse .navbar-nav .nav-item .nav-link img {
  display: flex;
  height: 20px;
  width: auto;
  margin-right: 5px;
}

@media (max-width: 768px) {
  .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
    color: var(--text-color-dark) !important;
    z-index: -2;
  }
}
