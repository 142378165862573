.publicacoes_bloco .publicacoes_block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 318px;
  padding: 15px;
  border: 1px solid var(--offwhite-light);
  background-color: white;
  border-radius: 5px;
  margin-top: 20px;
}

.publicacoes_bloco .publicacoes_block .publicacoes_block-img {
  width: fit-content;
  height: 116px;
  background: var(--secondary-color);
  border-radius: 5px;
  margin: auto;
}

.publicacoes_bloco .publicacoes_block .publicacoes_block-img img {
  width: 278px;
  height: 100%;
  max-height: 118px;
  object-fit: cover;
  border-radius: 5px;
}

.publicacoes_bloco .publicacoes_block .publicacoes_block-texto {
  margin: 15px 0px 0px;
  width: 100%;
}

@media (max-width: 768px) {
  .publicacoes_bloco .publicacoes_block .publicacoes_block-texto {
    white-space: initial;
    flex-wrap: wrap;
    display: flex;
  }
}

.publicacoes_bloco .publicacoes_block .publicacoes_block-texto p {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: var(--text-color-dark);
  opacity: 0.9;
  margin: 0;
  text-align: center;
}

.publicacoes_bloco .publicacoes_block .publicacoes_block-button .button {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px 0px;
  border-radius: 5px;
  background: var(--secondary-color);
  height: fit-content;
  font-size: 14px;
  border: none;
  font-weight: bold;
  color: var(--text-color-light);
}

.clickable {
  cursor: pointer;
  transition: transform 100ms ease-in-out;
}

.clickable:hover {
  transform: scale(1.05);
}
