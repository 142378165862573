.dermatofunctional_card {
  background-color: white;
  margin: 0 auto 50px;
  padding: 15px;
  border: 1px solid var(--offwhite-dark);
  border-radius: 20px;
  width: 250px;
  min-height: 120px;
  height: 132px;
  transition: border-color, box-shadow, height;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
}

.dermatofunctional_card.expanded {
  height: 100%;
  max-height: 182px;
  margin-bottom: 0;
  transition: border-color, box-shadow, height;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
}

.dermatofunctional_card .main_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100px;
}

@media (max-width: 768px) {
  .dermatofunctional_card {
    width: 70%;
    margin: 0 auto 20px;
  }

  .dermatofunctional_card.expanded {
    height: 190px;
    margin-bottom: 20px;
  }
}

.dermatofunctional_card:hover {
  border-color: var(--digital-documents-primary-color-light);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.dermatofunctional_card.disabled:hover {
  border-color: var(--offwhite-dark);
  box-shadow: none;
}

.dermatofunctional_card .title {
  font-weight: bold;
  text-align: left;
  margin: 0;
  font-size: 0.9rem;
}

.dermatofunctional_card .description {
  font-size: 0.6rem;
  text-align: left;
  margin: 5px 0;
  animation: fadeIn 250ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .dermatofunctional_card .description {
    width: 100%;
  }
}

.dermatofunctional_card .actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100% !important;
  align-items: center;
}

.dermatofunctional_card .link {
  color: var(--digital-documents-primary-color-light);
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  text-decoration: underline;
}

.dermatofunctional_card.disabled {
  background-color: var(--offwhite-medium);
  color: var(--text-color-dark);
}

.dermatofunctional_card .link.disabled {
  background-color: var(--offwhite-light);
  color: var(--text-color-dark);
  cursor: not-allowed;
}

.dermatofunctional_card.disabled:hover {
  background-color: var(--offwhite-medium);
  color: var(--text-color-dark);
}

.dermatofunctional_card .expand {
  color: var(--digital-documents-primary-color-light);
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  text-decoration: underline;
  border: none;
  background-color: transparent;
  text-decoration: none;
}

.dermatofunctional_card .expand svg {
  transition: transform 0.3s ease-in-out;
}

.dermatofunctional_card.expanded .expand svg {
  transition: transform 0.3s ease-in-out;
  transform: rotate(-180deg);
}

.dermatofunctional_card.disabled {
  background-color: var(--offwhite-light);
  color: var(--text-color-dark);
}

.dermatofunctional_card .link.disabled {
  background-color: var(--offwhite-light);
  color: var(--text-color-dark);
  cursor: not-allowed;
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
}

.dermatofunctional_card.disabled:hover {
  background-color: var(--offwhite-light);
  color: var(--text-color-dark);
}

.dermatofunctional_card.disabled:hover .info .image {
  filter: unset;
}
