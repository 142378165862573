.perks-club-container {
  width: 100%;
  height: fit-content;
}

.banner-container {
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(44, 85, 123, 0.54),
    rgba(44, 85, 123, 0.54)
  );
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.banner-filter {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("./images/perks-club-banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  filter: blur(10px);
  -webkit-filter: blur(10px);
  z-index: -1;
}

.banner {
  width: min(100%, 1400px);
  height: auto;
  margin: 0 auto;
  z-index: 1;
  box-shadow: 12px 0 15px -4px rgba(31, 73, 125, 0.8),
    -12px 0 8px -4px rgba(31, 73, 125, 0.8);
}

.menu-header {
  width: 100%;
  height: 60px;
  background-color: var(--perks-club-secondary-color-light);
}

.menu-header-buttons-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0 10%;
}

@media (max-width: 1300px) {
  .menu-header {
    display: none;
  }
}

.menu {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 20% 25px;
  background-image: url("./images/perks-club-background.png");
  background-position: center;
}

.menu-buttons-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 50px 0;
}

.become-partner-button-container {
  position: absolute;
  height: fit-content;
  width: fit-content;
  bottom: 20px;
  left: 20px;
}

.menu-header-button {
  text-transform: none;
  margin: 0;
  width: fit-content;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  text-align: center;
  margin: 0;
  color: var(--primary-color-darker);
}

.menu-buttons-container {
  width: 70%;
  min-height: 345px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 25px;
}

.menu-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
  cursor: pointer;
}

.menu-button-image-container {
  background-color: var(--primary-color-darker);
  cursor: pointer;
  transition: background-color 100ms ease-in-out;
  border-radius: 50%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-button-image-container:hover {
  background-color: var(--perks-club-secondary-color-light);
}

.menu-button-image-container img {
  display: flex;
  width: 35px;
  height: 35px;
  margin: auto;
}

.menu-button p {
  margin: 15px auto 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

@media (max-width: 960px) {
  .menu {
    /* padding: 25px 5px 100px; */
    padding: 25px 5px;
  }

  .menu-buttons-container {
    width: 100%;
    padding: 20px 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    height: fit-content;
    gap: 20px 10px;
  }

  .menu-button-image-container img {
    display: flex;
    width: 15px;
    height: 15px;
    margin: auto;
  }

  .menu-button p {
    font-size: 0.8rem;
  }

  .become-partner-button-container {
    left: calc(50% - 70px);
  }
}

.content-container {
  width: 100%;
  margin-bottom: -100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
  background: linear-gradient(
    180deg,
    var(--primary-color-dark) 0%,
    var(--primary-color-darker) 100%
  );
}

.content-carousel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.arrow {
  padding: 1rem;
}

.arrow:hover {
  cursor: pointer;
}

.content-container:has(.content-title) {
  padding: 30px 0;
}

.content-title {
  display: flex;
  flex-direction: row;
  justify-self: center;
  align-items: center;
  color: white;
  font-size: 4rem;
  font-weight: 600;
  filter: drop-shadow(11px 11px 7px rgba(0, 0, 0, 0.25));
  transition: all 300ms ease-in-out;
}

.content-title p {
  margin: 0px;
  letter-spacing: 0.1rem;
  text-align: center;
}

.content-title img {
  display: flex;
  height: 3rem;
  width: auto;
  margin-left: 15px;
  filter: invert(12%) sepia(63%) saturate(6234%) hue-rotate(320deg)
    brightness(87%) contrast(156%);
}

.content-cards-container {
  width: fit-content;
  max-width: 70%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 30px 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
}

.content-card {
  padding: 20px 15px;
  background: #f7f5f5;
  box-shadow: 0px 14px 11px 1px rgba(0, 0, 0, 0.18);
  border-radius: 20px;
  margin: 0 35px;
  width: 100%;
  max-width: 390px;
  min-width: 390px;
  height: 600px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-card .card-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
}

.content-card .image-container {
  position: relative;
  width: 300px;
  height: 225px;
  margin: 0 20px;
}

.content-card .image-filter {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(44, 85, 123, 0.54),
    rgba(44, 85, 123, 0.54)
  );
  box-shadow: 0px 14px 11px 1px rgba(0, 0, 0, 0.18);
  border-radius: 20px;
}

.content-card .image-container img {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}

.content-card .title {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  font-size: 1.8rem;
  text-align: center;
  color: var(--primary-color-darker);
  margin: 0;
  margin-top: 15px;
}

.content-card .separator {
  width: 90%;
  margin: 15px auto;
  height: 1px;
  background: var(--primary-color-darker);
}

.content-card .discount {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 29px;
  text-align: center;
  color: var(--primary-color-darker);
}

.content-card .discount span {
  font-size: 3rem;
  font-weight: 800;
}

.content-card .terms {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 13px;
  text-align: center;
  color: var(--primary-color-darker);
  margin: 0;
}

.content-card .button-container {
  width: calc(100% - 30px);
  position: absolute;
  bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.content-card .button-container .button {
  all: unset;
  background-color: var(--primary-color-darker);
  color: white;
  padding: 5px 50px;
  border-radius: 125px;
  cursor: pointer;
  transition-property: transform, background-color;
  transition-duration: 100ms;
  transition-timing-function: ease-in-out;
}

.content-card .button-container .button:hover {
  background-color: var(--primary-color-dark);
  transform: scale(1.1);
}

@media (max-width: 600px) {
  .content-title {
    display: flex;
    flex-direction: column-reverse;
    font-size: 2rem;
  }

  .content-title img {
    height: 5rem;
    margin: 0;
    margin-bottom: 10px;
  }

  .content-cards-container {
    max-width: 90%;
  }

  .content-card {
    width: 100%;
    max-width: 300px;
    min-width: 300px;
    height: 460px;
    margin: 0 15px;
  }

  .content-card .card-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
  }

  .content-card .image-container {
    position: relative;
    width: 230px;
    height: 130px;
    margin: 0 20px;
  }

  .content-card .title {
    font-size: 1.2rem;
    margin-top: 8px;
  }

  .content-card .discount {
    font-size: 1rem;
  }

  .content-card .discount span {
    font-size: 2rem;
  }

  .content-card .terms {
    font-size: 0.7rem;
  }
}

.scroll-observation {
  color: var(--perks-club-secondary-color);
  margin: 5px auto 0;
}

/* width */
.content-cards-container::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

/* Track */
.content-cards-container::-webkit-scrollbar-track {
  background: var(--offwhite-dark);
  border-radius: 8px;
}

/* Handle */
.content-cards-container::-webkit-scrollbar-thumb {
  background: var(--perks-club-secondary-color);
  border-radius: 8px;
}
