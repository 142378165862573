.footer__redes {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

footer .footer__redes p {
  color: var(--text-color-dark);
  font-size: 16px;
  font-weight: 700;
  margin: -25px 0 0;
  padding: 0;
}

@media screen and (max-width: 768px) {
  footer .footer__redes p {
    margin: 30px 0 0;
  }
}

footer .faq_link {
  color: var(--secondary-color);
  font-size: 24px;
  font-weight: 700;
  margin: 20px 0 0 0;
  padding: 0;
  transition: color 100ms ease-in-out;
}

footer .faq_link:hover {
  color: var(--secondary-color-light);
}
