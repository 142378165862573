.destaques_bloco--titulos.destaques {
  flex: 1;
  white-space: initial;
  margin-top: -50px;
  margin-bottom: 50px;
}

.destaques_bloco--titulos h2 {
  font-weight: bold;
  font-size: 47px;
  text-align: left;
  margin: 0;
}

@media (max-width: 375px) {
  .destaques_bloco--titulos h2 {
    font-size: 40px;
  }
}

.destaques_bloco--titulos p {
  /* width: 430px; */
  font-weight: normal;
  /* font-size: 14px; */
  text-align: left;
  opacity: 0.5;
  font-size: 18px;
  line-height: 24px;
}

.destaques_bloco--titulos.destaques h2,
.destaques_bloco--titulos.destaques p {
  color: var(--text-color-dark);
}

@media (min-width: 769px) {
  .horizontalR1 {
    margin-bottom: 25px !important;
  }
}

@media (max-width: 768px) {
  .destaques_bloco--titulos.destaques h2 {
    margin-top: 50px;
  }

  .horizontalR {
    display: flex;
  }

  .horizontalR1 {
    display: flex;
    width: 50% !important;
  }
}
