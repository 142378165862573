.display {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.react-multiple-carousel__arrow--right {
  right: 0 !important;
}

.react-multiple-carousel__arrow--left {
  left: 0 !important;
}
