.container .title_licitacao__andamento {
  width: 100%;
  margin-top: 25px;
}

.container .title_licitacao__andamento strong {
  background: var(--list-green);
  padding: 7px 15px;
  border-radius: 5px;
  color: var(--text-color-light);
  font-weight: 700;
  font-size: 16px;
  width: fit-content;
}

.container .title_licitacao__encerradas {
  width: 100%;
  margin-top: 45px;
  display: flex;
  flex-direction: column;
}

.container .title_licitacao__encerradas strong {
  background: var(--list-red);
  padding: 7px 15px;
  border-radius: 5px;
  color: var(--text-color-light);
  font-weight: 700;
  font-size: 16px;
  width: fit-content;
}

.container .title_licitacao__dispensadas {
  width: 100%;
  margin-top: 45px;
  display: flex;
  flex-direction: column;
}

.container .title_licitacao__dispensadas strong {
  background: var(--primary-color-medium);
  padding: 7px 15px;
  border-radius: 5px;
  color: var(--text-color-light);
  font-weight: 700;
  font-size: 16px;
  width: fit-content;
}

/* .container
  .title_licitacao__encerradas
  .licitacao__encerradas_block
  .licitacao__button
  button {
  border: 1px solid var(--text-color-dark);
  padding: 10px 15px;
  border-radius: 50px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
} */

.link_portaria {
  color: var(--secondary-color);
  transition: color 100ms ease-in-out;
}

.link_portaria:hover {
  color: var(--secondary-color-light);
}
