.breadcrumb {
  padding: 0.75rem 0 !important;
  width: 100%;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .breadcrumb {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.container .breadcrumb {
  background: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0px;
}

.container .breadcrumb .breadcrumb-item {
  display: flex;
  align-content: center;
  align-items: center;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  opacity: 0.5;
  color: var(--text-color-dark) !important;
}

/* .container .breadcrumb .breadcrumb-item a {
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  opacity: 0.5;
  color: var(--text-color-dark) !important;
} */

.container .breadcrumb .active {
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  opacity: 1;
  font-weight: 700;
}
