.ondeEstamosContainer {
  margin-bottom: -100px;
}

.home__ondeEstamos {
  width: 100%;
  padding: 20px 0px;
  margin-top: 50px;
  background: var(--offwhite-light);
}

.home__ondeEstamos .hold__ondeEstamos {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 10px;
}

.home__ondeEstamos .hold__ondeEstamos h3 {
  font-weight: bold;
  font-size: 47px;
  line-height: 45px;
  text-align: left;
  color: var(--text-color-dark);
}

.home__ondeEstamos .hold__ondeEstamos p {
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  color: var(--text-color-dark);
  opacity: 0.5;
  /* margin-bottom: 72px; */
}

.home__ondeEstamos .ondeEstamosId {
  max-width: 600px;
  margin: 0 auto;
}

.home__ondeEstamos .ondeEstamosId .card-wrapper {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .home__ondeEstamos .hold__ondeEstamos {
    justify-content: center;
  }

  .home__ondeEstamos .ondeEstamosId {
    margin-top: -5px;
  }

  .home__ondeEstamos .ondeEstamosId > .row {
    flex-wrap: nowrap;
    overflow-x: auto !important;
    white-space: initial !important;
    -webkit-overflow-scrolling: touch;
    -webkit-user-drag: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    cursor: grab;
  }

  .home__ondeEstamos .ondeEstamosId > .row::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }

  .home__ondeEstamos .ondeEstamosId > .row::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }

  .home__ondeEstamos .ondeEstamosId > .row::-webkit-scrollbar-button {
    width: 0;
  }

  .home__ondeEstamos .ondeEstamosId > .row > .col-md-3 {
    display: flex;
    float: none;
    flex: 0 0 auto !important;
    max-width: 300px;
  }
}
