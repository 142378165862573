.digital_documents_card {
  background-color: white;
  margin: auto;
  padding: 30px;
  border-radius: 10px;
  width: 400px;
  height: 250px;
  transition: background-color, color;
  transition-duration: 100ms;
  transition-timing-function: ease-in-out;
}

@media (max-width: 768px) {
  .digital_documents_card {
    width: 100%;
    margin: 0px 15px 30px;
  }
}

.digital_documents_card:hover {
  background-color: var(--digital-documents-primary-color-dark);
  color: var(--text-color-light);
}

.digital_documents_card .content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
}

.digital_documents_card .info {
  text-align: center;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.digital_documents_card .info .image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  color: var(--text-color-light);
  transition: filter 0.1s ease-in-out;
}

.digital_documents_card:hover .info .image {
  filter: invert(100%) sepia(3%) saturate(18%) hue-rotate(164deg)
    brightness(105%) contrast(100%);
}

.digital_documents_card .info .title {
  font-weight: bold;
  text-align: left;
  margin: auto;
  margin-left: 20px;
}

.digital_documents_card .description {
  font-size: 0.7rem;
  margin: 10px 0;
  text-align: left;
}

@media (max-width: 768px) {
  .digital_documents_card .description {
    width: 225px;
  }
}

.digital_documents_card .button {
  padding: 10px 20px;
  width: 150px;
  border-radius: 5px;
  background-color: var(--digital-documents-primary-color-light);
  color: var(--text-color-light);
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  border: none;
}

.digital_documents_card.disabled {
  background-color: var(--offwhite-medium);
  color: var(--text-color-dark);
}

.digital_documents_card .button.disabled {
  background-color: var(--offwhite-light);
  color: var(--text-color-dark);
  cursor: not-allowed;
}

.digital_documents_card.disabled:hover {
  background-color: var(--offwhite-medium);
  color: var(--text-color-dark);
}

.digital_documents_card.disabled:hover .info .image {
  filter: unset;
}
