.revista-home {
  width: 100%;
  border-radius: 10px;
  margin: 40px auto 0;
  display: flex;
  align-items: center;
  padding: 15px 25px;
  box-sizing: border-box;
  overflow-y: hidden;
}

@media (max-width: 768px) {
  .revista-home {
    flex-direction: column;
    align-items: flex-start;
  }
}

.revista-home .revista-home-texto {
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  color: var(--text-color-light);
}

@media (max-width: 768px) {
  .revista-home .revista-home-texto {
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    color: var(--text-color-light);
  }
}

.revista-home .revista-home-texto strong {
  font-size: 18px;
  color: var(--text-color-light);
}

.revista-home .revista-home-texto strong,
.revista-home .revista-home-texto p {
  width: 100%;
  text-align: left;
  margin: 0;
  color: var(--text-color-light);
}

.revista-home .revista-home-revistas {
  flex: 4;
  align-items: center;
  display: flex;
  justify-content: space-between;
  /* width: fit-content; */
  width: 100%;
}

.revista-home .revista-home-revistas .thumbsForRevista {
  /* width: fit-content; */
  width: 100%;
  height: fit-content;
  display: flex;
  flex-wrap: nowrap;
  overflow-y: hidden;
  background-color: var(--offwhite-light);
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 10px;
  border-radius: 8px;
  margin: 0 auto;
}

.thumbsForRevista p.coming_soon {
  width: 100%;
  font-weight: 700;
  font-size: 42px;
  text-align: center;
  margin: auto;
}

/* .revista-home .revista-home-revistas .thumbsForRevista::-webkit-scrollbar {
  display: none;
} */

/* width */
.revista-home .revista-home-revistas .thumbsForRevista::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

/* Track */
.revista-home
  .revista-home-revistas
  .thumbsForRevista::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* Handle */
.revista-home
  .revista-home-revistas
  .thumbsForRevista::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
  border-radius: 10px;
}

@media (max-width: 768px) {
  .revista-home .revista-home-revistas .thumbsForRevista {
    width: 100%;
    margin-top: 15px;
  }
}

/* .revista-home .revista-home-revistas .revistas__arrow {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: var(--secondary-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .revista-home .revista-home-revistas .revistas__arrow {
    display: none;
  }
} */
