.container .pagination {
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.container .pagination .pag__numbers {
  width: fit-content;
}

.container .pagination .pag__numbers ul {
  display: flex;
  max-width: 400px;
  justify-content: space-around;
}

.container .pagination .pag__numbers ul li {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 700;
  margin-left: 15px;
  transition: ease-in-out 320ms;
}

.container .pagination .pag__numbers ul li:hover {
  background: var(--secondary-color);
  color: var(--text-color-dark);
  cursor: pointer;
}

.container .pagination .pag__numbers ul li.pag_active {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 700;
  background: var(--secondary-color);
}

.container .pagination .btn__pagination {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .container .pagination .btn__pagination {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .container .pagination .btn__pagination .btn-mobel {
    display: flex;
    margin-bottom: 15px;
    width: 100%;
    justify-content: space-between;
  }
}

.container .pagination .btn__pagination button {
  width: fit-content;
  padding: 10px 15px;
  background: transparent;
  border: 2px solid var(--text-color-dark);
  margin: 0 8px 0;
  border-radius: 5px;
  transition: ease-in-out 320ms;
}

@media (max-width: 768px) {
  .container .pagination .btn__pagination button {
    flex: 1;
  }
}

.container .pagination .btn__pagination button:hover {
  background: var(--secondary-color);
  color: var(--text-color-light);
}
