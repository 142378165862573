footer .footer__contato {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -20px;
}

@media (max-width: 768px) {
  footer .footer__contato {
    margin-top: 50px;
    width: 100%;
  }
}

footer .footer__contato p {
  color: var(--text-color-dark);
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

footer .footer__contato ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  margin-top: 15px;
  max-width: 330px;
}

footer .footer__contato__link {
  color: var(--secondary-color);
}

footer .footer__contato__link:hover {
  color: var(--secondary-color-light);
}

footer .footer__contato ul li {
  color: var(--text-color-dark);
  font-size: 14px;
  font-weight: 400;
  list-style: none;
  margin-bottom: 12px;
  max-width: 300px;
}

footer .footer__contato ul li span {
  opacity: 0.5;
  font-weight: 700;
}

footer .faq_link {
  color: var(--secondary-color);
  font-size: 24px;
  font-weight: 700;
  margin: 20px 0 0 0;
  padding: 0;
  transition: color 100ms ease-in-out;
}

footer .faq_link:hover {
  color: var(--secondary-color-light);
}
