.bg-home .carousel_home {
  width: 100%;
  height: 400px;
  border-radius: 5px;
  z-index: 3;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .bg-home .carousel_home {
    height: 200px;
  }
}

.bg-home .carousel_home img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  max-height: 403px;
  object-fit: cover;
  object-position: unset;
  /* border-radius: 5px; */
  background: radial-gradient(var(--offwhite-light) 60%, var(--offwhite-dark));
}

@media (max-width: 768px) {
  .bg-home .carousel_home img {
    max-height: 220px;
    object-fit: cover;
  }
}

.swiper-container {
  width: 100%;
  height: 400px;
}

.swiper-wrapper {
  max-height: 400px;
}
