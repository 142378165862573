.title__publications .content-text {
  margin-bottom: 40px;
  margin-top: 30px;
}

.title__publications .content-text p {
  margin: 0;
}

.title__publications .content-text a {
  /* color: var(--text-color-links); */
  color: var(--secondary-color);
}

.title__publications .content-text ul {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.title__publications .content-text ul li {
  font-size: 14px;
  opacity: 1;
  list-style: none;
  margin-bottom: 25px;
  position: relative;
  margin-left: 15px;
  transition: ease-in-out 220ms;
}

.title__publications .content-text ul li:hover:before {
  width: 8px;
}

.title__publications .content-text ul li:before {
  content: "";
  width: 4px;
  height: 100%;
  position: absolute;
  left: -15px;
  background: var(--secondary-color);
  display: flex;
  transition: ease-in-out 220ms;
}

.title__publications .content-image img {
  max-width: 100%;
  max-height: 100%;
}
