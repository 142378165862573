/* GENERAL */
.digital_documents_container {
  border-top: 40px solid var(--offwhite-light);
  border-bottom: 40px solid var(--offwhite-light);
  background: var(--offwhite-light);
}

.section_title {
  font-size: 30px;
  font-weight: 600;
  margin-top: 50px;
  color: var(--text-color-dark);
  text-align: left;
}

.section_description {
  font-size: 15px;
  font-weight: 500;
  margin-top: 20px;
  color: var(--text-color-dark);
  text-align: left;
}

.digital_documents_container .section_title {
  margin: 0px auto;
}

.digital_documents_container .section_title,
.digital_documents_container .section_description {
  padding: 0 15px;
}

@media (min-width: 768px) {
  .section_title {
    font-size: 40px;
    text-align: center;
    width: 100%;
    max-width: 600px;
    margin: 50px auto 0;
  }

  .section_description {
    font-size: 20px;
    text-align: center;
    width: 100%;
    max-width: 600px;
    margin: 20px auto 0;
  }
}

.separator_container {
  height: 50px;
  background: var(--offwhite-light);
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.separator {
  width: 300px;
  height: 2px;
}

/* BANNERS */

.digital_documents_banner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 700px;
  background-image: url("./images/digital_documents_banner_mobile.png");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  padding: 20px;
}

.digital_documents_banner .title {
  font-size: 30px;
  font-weight: 700;
  color: var(--text-color-light);
  text-align: left;
  width: 100%;
  max-width: 400px;
}

.digital_documents_banner .description {
  font-size: 15px;
  font-weight: 500;
  color: var(--text-color-light);
  text-align: left;
  max-width: 500px;
}

.digital_documents_banner .buttons {
  display: flex;
  gap: 10px;
  margin: 0 auto;
}

.digital_documents_banner .buttons .button {
  padding: 10px 5px;
  width: 120px;
  border-radius: 5px;
  background-color: var(--digital-documents-primary-color-dark);
  color: var(--text-color-light);
  border: 1px solid var(--digital-documents-primary-color-light);
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.2s ease-in-out;
}

.digital_documents_banner .buttons .button:hover {
  background-color: var(--digital-documents-primary-color-light);
}

@media (min-width: 768px) {
  .digital_documents_banner {
    padding: 10%;
    height: 80dvh;
    justify-content: center;
    background-image: url("./images/digital_documents_banner_desktop.png");
    background-position: center;
  }

  .digital_documents_banner .buttons {
    gap: 20px;
    margin: 0;
  }

  .digital_documents_banner .buttons .button {
    padding: 10px 20px;
    width: 150px;
  }

  .digital_documents_banner .title {
    font-size: 50px;
    max-width: 700px;
  }

  .digital_documents_banner .description {
    font-size: 25px;
  }
}

/* PATIENT AREA */

.patient_area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90dvw;
  background: white;
  margin: 40px auto 0;
  padding: 40px;
  border-radius: 10px;
}

.patient_area .title_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.patient_area .title_container .title {
  font-size: 25px;
  font-weight: 600;
  color: var(--digital-documents-primary-color-dark);
  text-overflow: clip;
  margin: 0;
}

.patient_area .title_container .title_icon {
  display: flex;
  width: 70px;
  height: auto;
}

.patient_area_button_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
}

.patient_area_button_container .description {
  font-size: 14px;
  margin: 0;
}

.patient_area_button_container .button {
  padding: 10px 20px;
  width: 100%;
  border-radius: 5px;
  background-color: white;
  color: var(--digital-documents-primary-color-dark);
  border: 1px solid var(--digital-documents-primary-color-dark);
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.2s ease-in-out;
}

.patient_area_button_container .button.colored {
  color: var(--text-color-light);
  background: var(--digital-documents-primary-color-light);
  border: 1px solid var(--digital-documents-primary-color-light);
}

@media (min-width: 768px) {
  .patient_area {
    padding: 80px;
    max-width: 700px;
  }

  .patient_area .title_container .title {
    font-size: 40px;
  }

  .patient_area .title_container .title_icon {
    width: 100px;
  }

  .patient_area_button_container {
    width: 530px;
  }
}

/* DIGITAL DOCUMENTS CARDS */

.digital_documents {
  background-color: var(--offwhite-light);
}

.digital_documents_cards_grid {
  /* logn row with x overflow */
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding: 30px 15px 0px;
}

@media (min-width: 768px) {
  .digital_documents_cards_grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    width: fit-content;
    margin: 50px auto 0;
  }
}

/* DOCUMENTS MODELS PANEL */

.documents_models_panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}

.documents_models_panel .mobile_only {
  display: flex;
  width: 100%;
  height: auto;
  margin-top: 20px;
}

.documents_models_panel .desktop_only {
  display: none;
}

@media (min-width: 768px) {
  .documents_models_panel .mobile_only {
    display: none;
  }

  .documents_models_panel .desktop_only {
    display: flex;
    width: 70dvw;
    height: auto;
    margin-top: 50px;
  }
}

/* CALLS TO ACTIONS GRID */

.call-to-action {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  width: 70dvw;
  margin: 50px auto;
}

.call-to-action.desktop_reverse {
  flex-direction: row;
}

.call-to-action_text {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.call-to-action_text .call-to-action_title {
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: 600;
}

.call-to-action_text .call-to-action_description {
  width: 100%;
  max-width: 475px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

.call-to-action_image {
  display: flex;
  width: 450px;
}

@media (max-width: 768px) {
  .call-to-action {
    flex-direction: column;
    width: 90dvw;
  }

  .call-to-action.desktop_reverse {
    flex-direction: column;
  }

  .call-to-action_text {
    padding: 0;
    align-items: center;
    text-align: left;
  }

  .call-to-action_text .call-to-action_title {
    max-width: 100%;
    font-size: 30px;
  }

  .call-to-action_text .call-to-action_description {
    max-width: 100%;
    font-size: 14px;
  }

  .call-to-action_image {
    width: 100%;
    margin-top: 20px;
  }
}

/* PRESIDENT COMMENT */
.president_comment {
}

.president_comment .name {
  font-weight: 700;
  font-size: 2rem;
  text-align: center;
}

.president_comment .title {
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
}

.president_comment .comment {
  margin-top: 30px;
  text-align: center;
  font-size: 0.8rem;
}

.president_comment .separator {
  width: 35%;
  height: 2px;
  background-color: var(--text-color-dark);
  margin: 30px auto 0;
}

@media (min-width: 768px) {
  .president_comment {
    margin-top: 100px;
  }

  .president_comment .name {
    font-size: 3rem;
  }

  .president_comment .title {
    font-size: 1.5rem;
  }

  .president_comment .comment {
    font-size: 1rem;
    width: 600px;
    margin: 20px auto 0;
  }

  .president_comment .separator {
    width: 15%;
  }
}
