footer .newsletter {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: -20px;
}

footer .newsletter img {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 80px;
  height: auto;
}

footer .newsletter p {
  font-size: 34px;
  color: var(--secondary-color);
  font-weight: 900;
  margin: 0 auto;
  padding: 0;
  text-align: center;
}

footer .newsletter span {
  max-width: 85%;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: var(--text-color-dark);
  opacity: 0.5;
  margin: 0 auto;
}

footer .newsletter form {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 25px;
}

footer .newsletter form .campos {
  width: 100%;
  display: flex;
  align-items: center;
}

footer .newsletter form .campos p {
  font-size: 14px;
  margin: 0px 20px;
  font-weight: bold;
}

footer .newsletter form .campos input {
  flex: 1;
  border-radius: 5px;
  color: var(--text-color-dark);
  padding: 10px;
  box-sizing: content-box;
  width: 100%;
  border: 1px solid var(--text-color-dark);
  font-weight: 400;
  font-size: 14px;
  background: transparent;
}

footer .newsletter form .button {
  width: 100%;
  background: var(--secondary-color);
  color: var(--text-color-light);
  font-size: 16px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  padding: 15px 0px;
  border: none;
  border-radius: 5px;
  margin-top: 15px;
  transition: background-color 100ms ease-in-out;
}

footer .newsletter form .button:hover {
  background-color: var(--secondary-color-light);
}
