.card_gestao {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 300px;
  padding: 15px 0;
  margin: auto;
}

.card_gestao img {
  max-width: 100%;
  width: 100%;
  height: auto;
  object-fit: cover;
  transform: scale(1.15);
}

.card_gestao .gestao-photo {
  width: 180px;
  height: 180px;
  background: var(--offwhite-light);
  border-radius: 50%;
  border: 10px solid var(--primary-color-light);
  overflow: hidden;
}

/* .card_gestao .gestao-photo-diretoria {
  width: 180px;
  height: 180px;
  background: var(--offwhite-light);
  border-radius: 50%;
  border: 10px solid var(--primary-color-light);
  overflow: hidden;
} */

/* .card_gestao .gestao-photo-conselheiro {
  width: 180px;
  height: 180px;
  background: var(--offwhite-light);
  border-radius: 50%;
  border: 10px solid var(--primary-color-light);
  width: 180px;
  height: 180px;
  background: var(--offwhite-light);
  border-radius: 50%;
  overflow: hidden;
} */

.card_gestao .gestao-name {
  width: 100%;
  margin-top: 10px;
  font-weight: 700;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card_gestao .gestao-name p {
  margin: 0;
}
