.revista-home .revista-home-revistas .thumbsForRevista .thumbRevista {
  flex: 0 0 auto;
  width: 100%;
  max-width: 95px;
  background: var(--offwhite-light);
  height: 130px;
  border-radius: 5px;
  margin-right: 5px;
}

@media (max-width: 768px) {
  .revista-home .revista-home-revistas .thumbsForRevista .thumbRevista {
    margin-right: 10px;
  }
}

.revista-home .revista-home-revistas .thumbsForRevista .thumbRevista img {
  max-width: 100%;
  border-radius: 5px;
  height: 100%;
}
