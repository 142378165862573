.container .accordion__body {
  width: 100%;
}

.container .accordion__body .card {
  border: none;
}

.container .accordion__body .card:first-child {
  border-radius: 15px 15px 0px 0px;
}

.container .accordion__body .card:last-child {
  border-radius: 0px 0px 15px 15px;
}

.container .accordion__body .card-body {
  background: var(--offwhite-light);
}

.container .accordion__body .card-body a {
  color: var(--secondary-color);
}

.container .accordion__body .card-header {
  background: var(--secondary-color-light);
  border: 1px solid white;
}

.container .accordion__body .card-header-button {
  color: var(--text-color-light);
  font-weight: 700;
  backface-visibility: visible;
  cursor: pointer;
  padding: 8px 16px;
  outline: none;
}
