.container .tribute_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

@media (max-width: 425px) {
  .container .tribute_header {
    flex-direction: column;
  }
}

.container .tribute_header .initial_select {
  display: none;
}

.container .tribute_header .initials_buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media (max-width: 425px) {
  .container .tribute_header .initials_buttons {
    display: none;
  }
}

.container .tribute_header .initials_buttons button {
  margin: 0;
  height: 100%;
  padding: 17px 12px;
  border-top: 1px solid var(--offwhite-medium);
  border-bottom: 1px solid var(--offwhite-medium);
  border-left: none;
  border-right: none;
  border-radius: 0px;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out,
    border-color 0.1s ease-in-out;
}

.container .tribute_header .initials_buttons button:hover {
  background-color: var(--secondary-color);
  color: #fff;
  border-color: var(--secondary-color);
}

.container .tribute_header .initials_buttons button.active {
  background-color: var(--secondary-color);
  color: #fff;
  border-color: var(--secondary-color);
}

.container .tribute_header .initials_buttons button:first-child {
  border-radius: 10px 0px 0px 10px;
  border-left: 1px solid var(--offwhite-medium);
}

.container .tribute_header .initials_buttons button:last-child {
  border-radius: 0px 10px 10px 0px;
  border-right: 1px solid var(--offwhite-medium);
}

@media (max-width: 425px) {
  .container .tribute_header .initial_select {
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    max-width: 72dvw;
  }
}

.container .tribute_header .initial_select select {
  margin: 0;
  width: 100%;
}

.container .empty_tribute {
  margin-top: 45px;
}

.tributes_grid {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 0.9fr 0.1fr 0.9fr 0.1fr 0.9fr 0.1fr 0.9fr;
  grid-gap: 0px;
}

@media (max-width: 768px) {
  .tributes_grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 425px) {
  .tributes_grid {
    grid-template-columns: 1fr;
  }
}

.tributes_grid .divider {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30%;
  width: 1px;
  background-color: var(--offwhite-medium);
  margin: calc(175% + 35px) auto;
}

@media (max-width: 425px) {
  .tributes_grid .divider {
    display: none;
  }
}
