.bg-home .acesso {
  color: var(--text-color-light);
  font-weight: 400;
  font-size: 22px;
}

@media (max-width: 768px) {
  .bg-home .acesso {
    font-size: 18px;
  }
}

.bg-home .breadcrumbHome {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 425px) {
  .bg-home .breadcrumbHome {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.bg-home .breadcrumbHome .ajusteFonte {
  justify-self: flex-end;
  display: flex;
}

.bg-home .breadcrumbHome .ajusteFonte .diminuirFonte,
.bg-home .breadcrumbHome .ajusteFonte .fonteNormal,
.bg-home .breadcrumbHome .ajusteFonte .aumentarFonte,
/* .bg-home .breadcrumbHome .ajusteFonte .contrasteFundoPreto, */
.bg-home .breadcrumbHome .ajusteFonte .contrasteFundoBranco {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-left: 7px;
  font-weight: 700;
  cursor: pointer;
}

/* .bg-home .breadcrumbHome .ajusteFonte .contrasteFundoBranco {
  background: var(--offwhite-light);
  color: var(--text-color-dark);
  font-weight: 700;
} */

/* .bg-home .breadcrumbHome .ajusteFonte .contrasteFundoPreto {
  background: var(--text-color-dark);
  color: var(--text-color-light);
  font-weight: 700;
} */

.bg-home .breadcrumbHome .ajusteFonte .contrasteFundoBranco {
  font-weight: 700;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  background: var(--offwhite-light);
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease-in-out 120ms;
  color: var(--text-color-dark);
}

.bg-home .breadcrumbHome .ajusteFonte .contrasteFundoBranco:hover {
  transform: scale(1.2);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
