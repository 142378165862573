.national-salary-floor-banner-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}

.national-salary-floor-banner {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}
