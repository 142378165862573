#common-content {
  margin: 0 auto;
  max-width: 1200px;
}

.content-text p {
  margin: 0;
}

.content-text a {
  /* color: var(--text-color-links); */
  color: var(--secondary-color);
}

.content-text ul {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.content-text ul li {
  font-size: 14px;
  opacity: 1;
  list-style: none;
  margin-bottom: 25px;
  position: relative;
  margin-left: 15px;
  transition: ease-in-out 220ms;
}

.content-text ul li:hover:before {
  width: 8px;
}

.content-text ul li:before {
  content: "";
  width: 4px;
  height: 100%;
  position: absolute;
  left: -15px;
  background: var(--secondary-color);
  display: flex;
  transition: ease-in-out 220ms;
}

@media (max-width: 768px) {
  .content-text img {
    height: 100%;
    width: 100%;
    max-width: calc(100vw - 30px);
  }
}

.content-image {
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: scale-down;
}

.content-image img {
  max-width: 100%;
  max-height: 100%;
}

.report-iframe {
  width: 1100px;
  height: 620px;
  border: none;
  margin: auto;
}
