.board_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.board_row.not_full {
  justify-content: space-around;
}

@media screen and (max-width: 768px) {
  .board_row {
    flex-direction: column;
    align-items: flex-start;
  }
}
