.card--servicoSecundario {
  width: 220px;
  height: 115px;
  border-radius: 10px;
  background: var(--text-color-light);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  padding: 15px 10px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media (max-width: 768px) {
  .card--servicoSecundario {
    justify-content: center;
    flex-direction: column;
    height: 150px;
    margin-bottom: 25px;
  }
}

@media (max-width: 500px) {
  .card--servicoSecundario {
    padding: 15px 5px;
  }
}

.card--servicoSecundario .card--servicoSecundario--icone {
  color: var(--secondary-color);
  font-size: 20px;
  font-weight: 700;
  position: relative;
  display: flex;
  justify-content: flex-start;
}

@media (max-width: 768px) {
  .card--servicoSecundario .card--servicoSecundario--icone {
    margin-bottom: 15px;
  }
}

.card--servicoSecundario .card--servicoSecundario--icone img {
  max-width: 40px;
  height: auto;
}

.card--servicoSecundario .card--servicoSecundario--texto {
  width: 100%;
  margin-left: 15px;
}

@media (max-width: 768px) {
  .card--servicoSecundario .card--servicoSecundario--texto {
    margin-left: 0;
  }
}

.card--servicoSecundario .card--servicoSecundario--texto--span {
  font-size: 8px;
  font-weight: 400;
  margin: 0px 0px 5px;
  padding: 0;
  opacity: 0.8;
  /* letter-spacing: 2px; */
  text-transform: uppercase;
}

.card--servicoSecundario .card--servicoSecundario--texto--span span {
  font-size: 9px;
}

@media (max-width: 768px) {
  .card--servicoSecundario .card--servicoSecundario--texto--span {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.card--servicoSecundario .card--servicoSecundario--texto p {
  font-size: 16px;
  font-weight: 700;
  margin: -5px 0px 0px 0px;
  padding: 0;
  opacity: 0.8;
  line-height: 20px;
}

@media (max-width: 768px) {
  .card--servicoSecundario .card--servicoSecundario--texto p {
    width: 100%;
    text-align: center;
    margin: -2px 0px 0px 0px;
  }
}

@media (max-width: 375px) {
  .card--servicoSecundario .card--servicoSecundario--texto p {
    font-size: 14px;
  }
}
