.redes__sociais {
  width: 100%;
  display: flex;
  margin-top: 25px;
}

.redes__sociais .rede {
  margin-right: 10px;
  width: 35px;
  height: 35px;
  background: var(--text-color-light);
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease-in-out 120ms;
}

.redes__sociais .rede .fab,
.redes__sociais .rede span {
  color: var(--secondary-color);
  font-size: 20px;
}

@media (max-width: 375px) {
  .redes__sociais .rede {
    width: 35px;
    height: 35px;
  }
}

.redes__sociais .rede:hover {
  transform: scale(1.2);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
