/* BANNERS */
.dermatofunctional_banner {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: var(--digital-documents-primary-color-medium);
  width: 100%;
  height: 100%;
  padding: 20px 10px 100px;
}

.dermatofunctional_banner .button {
  width: 100%;
  padding-bottom: 20px;
  color: var(--text-color-light);
  font-weight: 500;
  cursor: pointer;
  text-align: left;
  transition: background-color 0.2s ease-in-out;
}

.dermatofunctional_banner .title {
  font-size: 30px;
  font-weight: 600;
  color: var(--text-color-light);
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
}

.dermatofunctional_banner .description {
  font-size: 15px;
  font-weight: 300;
  color: var(--text-color-light);
  text-align: center;
  max-width: 500px;
  margin: 0;
}

@media (min-width: 768px) {
  .dermatofunctional_banner {
    width: 100%;
    justify-content: center;
    background-position: center;
    padding-bottom: 150px;
  }

  .dermatofunctional_banner .button {
    padding: 10px 20px;
    font-size: 1.3rem;
  }

  .dermatofunctional_banner .title {
    font-size: 50px;
    width: 100%;
    max-width: 1000px;
  }

  .dermatofunctional_banner .description {
    font-size: 25px;
    max-width: 1000px;
  }
}

/* DIGITAL DOCUMENTS CARDS */
.dermatofunctional_cards_container {
  margin-top: -100px;
}

.dermatofunctional_cards_grid {
  display: flex;
  flex-direction: column;
  padding: 30px 15px 0px;
}

@media (min-width: 768px) {
  .dermatofunctional_cards_container {
    margin-top: -150px;
  }

  .dermatofunctional_cards_grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    width: fit-content;
    margin: 50px auto 0;
  }
}
