.news_web {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.news_highlights {
}

.news_secondary {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 10px;
}

@media (max-width: 768px) {
  .news_web {
    display: none;
  }
}

.news_mobile {
  display: none;
}

@media (max-width: 768px) {
  .news_mobile {
    display: block;
  }
}

.noticias_bloco {
  width: 100%;
  /* padding: 25px 0px; */
  padding: 0;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .news_mobile > .row {
    flex-wrap: nowrap;
    overflow-x: auto !important;
    white-space: initial !important;
    -webkit-overflow-scrolling: touch;
    -webkit-user-drag: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    cursor: grab;
  }

  .news_mobile > .row::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }

  .news_mobile > .row::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }

  .news_mobile > .row::-webkit-scrollbar-button {
    width: 0;
  }

  .news_mobile > .row > .col-md-3 {
    display: flex;
    float: none;
    flex: 0 0 auto !important;
    max-width: 318px;
  }
}

.noticias_bloco .noticias_bloco--titulo {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .noticias_bloco .noticias_bloco--titulo {
    flex-direction: column;
  }
}

.noticias_bloco .noticias_bloco--titulo .noticias_bloco--titulos {
  flex: 1;
  white-space: initial;
}

.noticias_bloco .noticias_bloco--titulo .noticias_bloco--titulos h2 {
  font-weight: bold;
  font-size: 47px;
  text-align: left;
  color: var(--text-color-dark);
  margin: 0;
}

@media (max-width: 375px) {
  .noticias_bloco .noticias_bloco--titulo .noticias_bloco--titulos h2 {
    font-size: 40px;
  }
}

.noticias_bloco .noticias_bloco--titulo .noticias_bloco--titulos p {
  /* width: 430px; */
  font-weight: normal;
  /* font-size: 14px; */
  text-align: left;
  color: var(--text-color-dark);
  opacity: 0.5;
  font-size: 18px;
  line-height: 24px;
}

.noticias_bloco .noticias_bloco--titulo .noticias_bloco--button {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 768px) {
  .noticias_bloco .noticias_bloco--titulo .noticias_bloco--button {
    justify-content: flex-start;
    margin: 15px 0px;
  }
}

.noticias_bloco .noticias_bloco--titulo .noticias_bloco--button .button {
  width: fit-content;
  padding: 10px 20px;
  border-radius: 50px;
  background: var(--secondary-color);
  height: fit-content;
  font-size: 14px;
  border: none;
  color: var(--text-color-light);
}

.noticias_bloco .noticias_bloco--titulo .noticias_bloco--button .button img {
  margin-left: 15px;
}
