.container .title__publications .publications_block {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 35px;
  margin-bottom: 35px;
  padding-bottom: 35px;
  border-bottom: 1px solid var(--offwhite-medium);
  display: flex;
  flex-direction: row;
}

@media (max-width: 768px) {
  .container .title__publications .publications_block {
    flex-direction: column !important;
    justify-content: space-between;
    align-items: center;
  }
}

.container .title__publications .publications_block:last-child {
  border: none;
}

.container .title__publications .publications_block .publication-content {
  font-size: 16px;
  color: var(--text-color-dark);
  letter-spacing: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 100%;
}

.container
  .title__publications
  .publications_block
  .publication-content
  .title {
  margin: 0;
  font-weight: 700;
}

.container .title__publications .publications_block .publication-content .call {
  margin: 0;
  font-weight: 400;
}

.container .title__publications .publications_block span {
  font-size: 14px;
  color: var(--text-color-dark);
  font-weight: 400;
  opacity: 0.5;
}

.publication-image {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
}

.publication-image img {
  margin-right: 20px;
  max-height: 150px;
  width: auto;
  height: 100%;
  border-radius: 20px;
}

@media (max-width: 768px) {
  .publication-image img {
    max-height: unset;
    width: 100%;
    height: auto;
    margin: 0 auto 20px;
  }
}
