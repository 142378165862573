@charset "UTF-8";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,600;0,700;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0");

:root {
  --primary-color-lightest: #4eacc7;
  --primary-color-lighter: #54d6f5;
  --primary-color-light: #49cff2;
  --primary-color-medium: #3fc6ee;
  --primary-color-dark: #2988c7;
  --primary-color-darker: #074269;
  /* --primary-color-dark: #4da3bc;
  --primary-color-darker: #0c526c; */
  --secondary-color-light: #4da3bc;
  --secondary-color: #2988c7;
  --perks-club-secondary-color-light: #ffc848;
  --perks-club-secondary-color: #ffb914;
  /* --perks-club-primary-color-dark: #2988c7;
  --perks-club-primary-color-darker: #074269; */
  --digital-documents-primary-color-light: #3fc6ee;
  --digital-documents-primary-color-medium: #006395;
  --digital-documents-primary-color-dark: #02344d;
  --text-color-light: #ffffff;
  --text-color-medium: #333333;
  --text-color-dark: #232323;
  --list-green: #10be10;
  --list-red: #ff3c08;
  --offwhite-light: #f2f2f2;
  --offwhite-medium: #dddddd;
  --offwhite-dark: #cccccc;
  --black: #000;
}

* {
  margin: 0;
  padding: 0;
  /* box-sizing: content-box; */
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif !important;
  min-height: 100vh;
}

a {
  color: unset;
  text-decoration: none !important;
}

a:hover {
  color: inherit;
}

button:focus {
  outline: none;
}

#root {
  min-height: 100vh;
  position: relative;
  /* padding-bottom: 429px; */
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1340px !important;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--offwhite-dark);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--secondary-color-light);
}

::file-selector-button {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
