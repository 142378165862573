.login .login__container .tipo__login .login_user .access_button_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: fit-content;
}

.login .login__container .tipo__login .login_user img {
  max-width: 75px;
  height: auto;
}

@media (max-width: 768px) {
  .login .login__container .tipo__login .login_user img {
    width: 50px;
    max-width: 100%;
  }
}

.login .login__container .tipo__login .login_user p {
  width: 100%;
  margin-top: 25px;
  text-align: center;
  font-weight: bold;
  font-size: 23px;
  line-height: 21px;
  color: var(--text-color-dark);
  font-size: 18px;
  font-weight: 700;
  color: var(--text-color-dark);
}

.login .login__container .tipo__login .login_user p span {
  width: 100%;
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.17em;
  line-height: 21px;
  color: var(--text-color-dark);
  opacity: 0.5;
}
