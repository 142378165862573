/* .swiper-slide {
  position: relative;
} */

.swiper-slide img {
  border-radius: 5px;
  position: relative;
}

.swiper-slide .overflow_banner {
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.7); */
  /* background: rgba(0, 0, 0, 0.2); */
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
}

/* .swiper-slide .overflow_banner .texto__banner {
  position: absolute;
  left: 50px;
  bottom: 50px;
  width: 280px;
  border-left: 3px solid var(--secondary-color);
  padding-left: 15px;
  text-align: left;
}

@media (max-width: 768px) {
  .swiper-slide .overflow_banner .texto__banner {
    left: 30px;
    bottom: 35px;
  }
} */

.swiper-slide .overflow_banner .texto__banner p {
  font-weight: bold;
  font-size: 23px;
  line-height: 21px;
  text-align: left;
  color: var(--secondary-color);
}

@media (max-width: 768px) {
  .swiper-slide .overflow_banner .texto__banner p {
    font-size: 18px;
    line-height: 18px;
    max-width: 90%;
  }
}

.swiper-slide .overflow_banner .texto__banner span {
  width: 273px;
  height: 98px;
  font-family: Poppins;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* text-align: left; */
  color: var(--text-color-light);
  opacity: 0.7;
}

@media (max-width: 768px) {
  .swiper-slide .overflow_banner .texto__banner span {
    font-size: 12px;
    line-height: 18px;
  }
}
