.navbar {
  height: 100px;
  background: var(--primary-color-medium);
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

.navbar-brand {
  /* flex: 1; */
  padding: 0 !important;
  margin: 0 !important;
}

@media (max-width: 991px) {
  .navbar-brand {
    max-width: 30%;
  }
}

@media (max-width: 767px) {
  .navbar-brand {
    max-width: 50%;
  }
}

.navbar .cristo {
  position: absolute;
  right: 15px;
  height: 100px;
  z-index: -1;
}

.navbar-brand .laco {
  max-height: 60px;
  cursor: pointer;
}

.navbar-brand img.laco {
  height: 100%;
}

.navbar-brand img.logobrasao {
  max-width: 30%;
  height: auto;
  max-height: 82px;
}

@media (max-width: 991px) {
  .navbar-brand img.logobrasao {
    max-width: 30%;
    height: auto;
  }
}

@media (max-width: 991px) {
  .navbar-brand .logobrasaos {
    max-width: 50vw;
    height: auto;
  }
}

.navbar .navbar-collapse .navbar-nav {
  align-items: center;
  text-align: center;
}

.navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
  font-weight: 700;
  font-size: 16px;
  color: var(--text-color-light) !important;
}

.mobile {
  display: none;
}

@media (max-width: 991px) {
  .mobile {
    display: block;
  }
}

.dropdown-toggle {
  display: block !important;
}

@media (max-width: 991px) {
  .dropdown-toggle {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .navbar .navbar-collapse .navbar-nav .nav-item .nav-link,
  .hidden-nav-link {
    color: var(--text-color-dark) !important;
    z-index: -2;
    font-weight: 700;
  }
}

.navbar-dropdown-button {
  visibility: hidden;
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

@media (max-width: 991px) {
  .navbar-dropdown-button {
    visibility: visible;
  }
}

.navbar .navbar-collapse {
  justify-content: flex-end;
}

@media (max-width: 991px) {
  .navbar-collapse {
    background: var(--text-color-light);
    width: 100vw;
    padding: 15px;
    margin-top: 21px;
    z-index: 9999;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    top: 79px;
  }
}

@media all and (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: none;
  }
  .navbar .nav-item:hover .nav-link {
    color: #fff;
  }
  .navbar .nav-item:hover .dropdown-menu {
    display: block;
  }
  .navbar .nav-item .dropdown-menu {
    margin-top: 0;
  }
  .navbar .dropdown-button {
    background: transparent;
    outline: none;
    border: 0;
  }
}
