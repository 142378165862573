.search-form {
  max-height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  border-radius: 6px;
  background-color: var(--offwhite-light);
}

.search-form input {
  width: 100%;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
  border: none;
  font-size: 14px;
  margin: 5px;
  outline: none;
  background-color: var(--offwhite-light);
}

.search-form .search-button {
  cursor: pointer;
  background-color: var(--secondary-color);
  padding: 5px;
  border-radius: 0 5px 5px 0;
  color: var(--text-color-light);
}
