.login {
  width: 100%;
  height: 100vh;
  /* background: var(--primary-color-medium); */
  background: var(--primary-color-darker);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.login .login__container {
  max-width: 600px;
  display: flex;
  flex-direction: column;
}

.login .login__container .login__logo {
  width: 100%;
  justify-content: center;
  display: flex;
}

.login .login__container .login__logo img {
  max-width: 800px;
  width: 100%;
}

@media (max-width: 768px) {
  .login .login__container .login__logo img {
    display: flex;
    width: 200px;
    height: 35px;
  }
}

.login .login__container .login__text {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 10px;
}

.login .login__container .login__text h1 {
  font-weight: bold;
  font-size: 35px;
  text-align: left;
  color: var(--text-color-light);
}

@media (max-width: 768px) {
  .login .login__container .login__text h1 {
    font-size: 20px;
  }
}

.login .login__container .login__text p {
  font-weight: normal;
  font-size: 21px;
  text-align: left;
  color: var(--text-color-light);
}

@media (max-width: 768px) {
  .login .login__container .login__text p {
    font-size: 14px;
    margin: 0;
  }
}

.login .login__container .tipo__login {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .login .login__container .tipo__login {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.login .login__container .tipo__login .login_user {
  width: 100%;
  max-width: 300px;
  height: 240px;
  border-radius: 5px;
  background: var(--text-color-light);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 15px;
  transition: ease-in-out 350ms;
}

@media (max-width: 768px) {
  .login .login__container .tipo__login .login_user {
    width: 100%;
    max-width: 200px;
    height: unset;
    padding: 15px 0px;
  }
}

.login .login__container .tipo__login .login_user:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.login .login__container .disclaimer {
  max-width: 480px;
  margin: 20px;
  color: var(--offwhite-dark);
  text-align: justify;
  font-size: 14px;
}

#modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  z-index: 5;
}

#modal .content {
  width: 100%;
  max-width: 420px;
  margin: auto 15px;
}

#modal .box {
  padding: 20px;
  border-radius: 8px;
  background-color: var(--offwhite-light);
}

#modal .box h1 {
  text-align: center;
}

#modal .box p {
  margin: 35px 0 0;
  text-align: justify;
}

#modal .close {
  display: flex;
  color: var(--text-color-dark);
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
}

#modal.hide {
  display: none;
}
