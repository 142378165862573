.container .title__publications .publications_block.tribute {
  width: 100%;
  height: auto;
  position: relative;
  margin: 35px auto !important;
  padding-bottom: 0px !important;
  border-bottom: 1px solid var(--offwhite-medium);
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .container .title__publications .publications_block.tribute {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.container .title__publications .publications_block:last-child {
  border-bottom: 1px solid var(--offwhite-medium);
}

.container .title__publications .publications_block .tribute-image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .container .title__publications .publications_block .tribute-image {
    max-width: 100%;
    margin-bottom: 10px;
  }
}

.container .title__publications .publications_block .tribute-image img {
  object-fit: cover;
  border: 1px solid var(--offwhite-medium);
  border-radius: 0px;
  width: 250px;
  height: 250px;
  border-radius: 10px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .container .title__publications .publications_block .tribute-image img {
    max-width: 100%;
    max-height: 100%;
  }
}

.container .title__publications .publications_block .tribute-content {
  margin: 10px;
  font-size: 16px;
  color: var(--text-color-dark);
  letter-spacing: 1px;
  text-align: center;
  width: calc(100%, -20px);
}

.container .title__publications .publications_block .tribute-content .title {
  margin: 0;
  font-weight: 700;
}

.container .title__publications .publications_block .tribute-content .call {
  margin: 0;
  font-weight: 400;
}

.container .title__publications .publications_block span {
  font-size: 14px;
  color: var(--text-color-dark);
  font-weight: 400;
  opacity: 0.5;
}
