.author_signature {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding-top: 20px;
}

.author_signature a {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--text-color-dark);
  opacity: 0.5;
}
