.container .title__page {
  width: 100%;
  margin: 80px 0px 25px 0px;
}

@media (max-width: 768px) {
  .container .title__page {
    margin: 20px 0px 25px 0px;
  }
}

.container .title__page .title {
  font-weight: 700;
  font-size: 24px;
  color: var(--text-color-dark);
}

.container .title__page .subtitle {
  font-weight: 700;
  font-size: 20px;
  /* color: var(--text-color-dark); */
  color: var(--text-color-medium);
}

.container .title__page span {
  font-size: 14px;
  opacity: 0.5;
  margin-top: -15px;
  display: flex;
}
