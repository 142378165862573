.container .title_licitacao__dispensadas .licitacao__dispensadas_block {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 25px;
}

.container .title_licitacao__dispensadas .licitacao__dispensadas_block:before {
  content: "";
  height: 100%;
  border-left: 5px solid var(--primary-color-medium);
  position: absolute;
  padding-left: 15px;
}

.container .title_licitacao__dispensadas .licitacao__dispensadas_block p {
  font-size: 16px;
  color: var(--text-color-dark);
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-left: 20px;
  margin-bottom: -3px;
}

.container
  .title_licitacao__dispensadas
  .licitacao__dispensadas_block
  .licitacao__dispensadas_info {
  margin-left: 20px;
  display: flex;
}

.container
  .title_licitacao__dispensadas
  .licitacao__dispensadas_block
  .licitacao__dispensadas_info
  span {
  font-size: 14px;
  color: var(--text-color-dark);
  font-weight: 400;
  opacity: 0.5;
}

.container
  .title_licitacao__dispensadas
  .licitacao__dispensadas_block
  .licitacao__button {
  margin-top: 15px;
  margin-left: 15px;
}
