#carlinhos {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 25vw;
  position: fixed;
  bottom: 15vh;
  right: 0;
  z-index: 5;
}

#carlinhos .content {
  width: 100%;
  max-width: 420px;
  margin: auto 15px;
  position: fixed;
  bottom: 50;
  right: 0;
}

#carlinhos .box {
  padding: 20px;
  border-radius: 8px;
  background-color: transparent;
}

#carlinhos .box h1 {
  text-align: center;
}

#carlinhos .box p {
  margin: 35px 0 0;
  text-align: justify;
}

#carlinhos .close {
  display: flex;
  color: transparent;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
}

#carlinhos .close-icon {
  padding: 5px 6px 6px 5px;
  background-color: red;
  border-radius: 50%;
}

#carlinhos.hide {
  display: none;
}

#carlinhos img {
  display: flex;
  height: 50vh;
  width: auto;
  margin: 0 auto;
  cursor: pointer;
}

.carlinhos-button {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10;
  padding: 50px;
  cursor: pointer;
}

.carlinhos-button img {
  display: flex;
  width: 100px;
  height: auto;
  border: 3px solid var(--primary-color-darker);
  border-radius: 50%;
}
