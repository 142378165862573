.link-card {
  margin: 20px 30px 20px;
  transition: transform 200ms ease-in-out;
}

.link-card:hover {
  transform: scale(1.1);
}

.link-card-image-container {
  position: relative;
}

.link-card-image-container img {
  width: 125px;
  height: auto;
  margin: 0 auto;
  position: absolute;
  left: calc(50% - 60px);
  top: calc(50% - 60px);
}

.link-card-image-container .background {
  width: 120px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  margin: auto;
}

.link-card-title {
  text-align: center;
  margin: 20px auto 0;
  font-weight: bold;
  color: white;
  font-size: 1.5rem;
  text-wrap: center;
  width: 200px;
}
