.denuncia--container__wrapper .denuncia--content .denuncia__form {
  flex: 2;
  padding-left: 15px;
  max-width: 900px;
}

@media (max-width: 768px) {
  .denuncia--container__wrapper .denuncia--content .denuncia__form {
    padding-left: 0px;
  }
}

.denuncia--container__wrapper .denuncia--content .denuncia__form form {
  width: 100%;
  background: var(--text-color-light);
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16);
  padding: 25px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.denuncia--container__wrapper
  .denuncia--content
  .denuncia__form
  form
  .form__title {
  width: 90%;
  margin: 15px 0px;
}

.denuncia--container__wrapper
  .denuncia--content
  .denuncia__form
  form
  .form__title
  p {
  color: var(--secondary-color);
  font-weight: 700;
  font-size: 22px;
  position: relative;
}

.denuncia--container__wrapper
  .denuncia--content
  .denuncia__form
  form
  .form__title
  p:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 5px;
  background: var(--secondary-color);
  bottom: 0;
  left: 0;
}

.denuncia--container__wrapper
  .denuncia--content
  .denuncia__form
  form
  .form__options {
  width: 90%;
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}

.denuncia--container__wrapper
  .denuncia--content
  .denuncia__form
  form
  .form__options
  .option__column {
  flex: 1;
  margin-left: 8px;
}

.denuncia--container__wrapper
  .denuncia--content
  .denuncia__form
  form
  .form__options
  .option__column
  p {
  font-size: 16px;
  opacity: 0.8;
}

.denuncia--container__wrapper
  .denuncia--content
  .denuncia__form
  form
  .form__options
  .option__column
  ul {
  width: 100%;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
}

.denuncia--container__wrapper
  .denuncia--content
  .denuncia__form
  form
  .form__options
  .option__column
  ul
  li {
  align-items: center;
  display: flex;
  list-style: none;
  font-size: 14px;
  height: 20px;
  margin-top: 5px;
}

.denuncia--container__wrapper
  .denuncia--content
  .denuncia__form
  form
  .form__options
  .option__column
  ul
  li
  input {
  margin-right: 15px;
}

.denuncia--container__wrapper
  .denuncia--content
  .denuncia__form
  form
  .form__divisor {
  width: 90%;
  height: 2px;
  background: var(--secondary-color);
  margin: 45px 0px;
}

.denuncia--container__wrapper
  .denuncia--content
  .denuncia__form
  form
  .form__group {
  width: 90%;
  display: flex;
  flex-direction: column;
}

.denuncia--container__wrapper
  .denuncia--content
  .denuncia__form
  form
  .form__group
  input,
select {
  width: 100%;
  padding: 15px;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
  border: 1px solid var(--offwhite-dark);
  font-size: 14px;
  margin: 5px;
}

.denuncia--container__wrapper
  .denuncia--content
  .denuncia__form
  form
  .form__group
  textarea {
  width: 100%;
  padding: 15px;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
  border: 1px solid var(--offwhite-dark);
  font-size: 14px;
  margin: 5px;
}

.denuncia--container__wrapper
  .denuncia--content
  .denuncia__form
  form
  .form__group
  .button {
  width: 100%;
  padding: 15px;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
  background: var(--secondary-color);
  color: var(--text-color-light);
  border: none;
  border-radius: 5px;
  font-size: 14px;
  margin: 15px 5px 25px;
  cursor: pointer;
}

.denuncia--container__wrapper
  .denuncia--content
  .denuncia__form
  form
  .form__group
  .form__group--half {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.denuncia--container__wrapper
  .denuncia--content
  .denuncia__form
  form
  .form__group
  .form__group--half
  input {
  flex: 1;
  padding: 15px;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
  border: 1px solid var(--offwhite-dark);
  font-size: 14px;
  margin: 5px;
}

.denuncia--container__wrapper
  .denuncia--content
  .denuncia__form
  form
  .form__group
  .form__group--half
  input
  + input {
  margin-right: -5px;
}

.denuncia--container__wrapper
  .denuncia--content
  .denuncia__form
  form
  .form__group
  .form__group--half
  select {
  width: 50%;
  padding: 15px;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
  border: 1px solid var(--offwhite-dark);
  font-size: 14px;
  margin: 5px;
}

label {
  margin: 0px 5px -2px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.denuncia--container__wrapper
  .denuncia--content
  .denuncia__form
  form
  .form__group
  .user_authorization {
  display: flex;
  margin-top: 15px;
}

.denuncia--container__wrapper
  .denuncia--content
  .denuncia__form
  form
  .form__group
  .user_authorization
  input {
  width: 100%;
  max-width: 20px;
}
