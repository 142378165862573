/* DEFAULT */

.publication-content {
  height: 100%;
}

.noticias_bloco .noticia_block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 318px;
  padding: 15px;
  border: 1px solid var(--offwhite-light);
  background-color: white;
  border-radius: 5px;
}

.noticias_bloco .noticia_block .noticia_block-img {
  width: fit-content;
  height: 116px;
  background: var(--secondary-color);
  border-radius: 5px;
  margin: auto;
}

.noticias_bloco .noticia_block .noticia_block-img img {
  width: 278px;
  height: 100%;
  max-height: 118px;
  object-fit: cover;
  border-radius: 5px;
}

.noticias_bloco .noticia_block .noticia_block-texto {
  margin: 15px 0px;
  width: 100%;
}

@media (max-width: 768px) {
  .noticias_bloco .noticia_block .noticia_block-texto {
    white-space: initial;
    flex-wrap: wrap;
    display: flex;
  }
}

.noticias_bloco .noticia_block .noticia_block-texto p {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: var(--text-color-dark);
  opacity: 0.9;
}

.noticias_bloco .noticia_block .noticia_block-button .button {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px 0px;
  border-radius: 5px;
  background: var(--secondary-color);
  height: fit-content;
  font-size: 14px;
  border: none;
  font-weight: bold;
  color: var(--text-color-light);
}

/* HIGLIGHT */

.noticias_bloco .noticia_block.highlight {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
  border: 1px solid var(--offwhite-light);
  background-color: white;
  border-radius: 5px;
}

.noticias_bloco .noticia_block.highlight .noticia_block-img {
  width: fit-content;
  height: auto;
  background: var(--secondary-color);
  border-radius: 5px;
  margin: auto;
}

.noticias_bloco .noticia_block.highlight .noticia_block-img img {
  width: 100%;
  height: auto;
  max-height: unset;
  object-fit: cover;
  border-radius: 5px;
}

.noticias_bloco .noticia_block.highlight .noticia_block-texto {
  margin: 15px 0px;
  width: 100%;
}

@media (max-width: 768px) {
  .noticias_bloco .noticia_block.highlight .noticia_block-texto {
    white-space: initial;
    flex-wrap: wrap;
    display: flex;
  }
}

.noticias_bloco .noticia_block.highlight .noticia_block-texto p {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 30px;
  text-align: left;
  width: 90%;
  margin: 0 auto;
  color: var(--text-color-dark);
  opacity: 0.9;
}

.noticias_bloco .noticia_block.highlight .noticia_block-button .button {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 10px 0px;
  border-radius: 5px;
  background: var(--secondary-color);
  height: fit-content;
  font-size: 14px;
  border: none;
  font-weight: bold;
  color: var(--text-color-light);
}

/* SECONDARY */

.noticias_bloco .noticia_block.secondary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 15px;
  border: 1px solid var(--offwhite-light);
  background-color: white;
  border-radius: 5px;
}

.noticias_bloco .noticia_block.secondary .noticia_block-img {
  width: fit-content;
  height: 116px;
  background: var(--secondary-color);
  border-radius: 5px;
  margin: auto;
}

.noticias_bloco .noticia_block.secondary .noticia_block-img img {
  width: 278px;
  height: 100%;
  max-height: 118px;
  object-fit: cover;
  border-radius: 5px;
}

.noticias_bloco .noticia_block.secondary .publication-content .card_text {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.noticias_bloco .noticia_block.secondary .noticia_block-texto {
  margin: 0;
  width: 100%;
}

@media (max-width: 768px) {
  .noticias_bloco .noticia_block.secondary .noticia_block-texto {
    white-space: initial;
    flex-wrap: wrap;
    display: flex;
  }
}

.noticias_bloco .noticia_block.secondary .noticia_block-texto p {
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  width: 95%;
  text-align: left;
  color: var(--text-color-dark);
  opacity: 0.9;
  margin: 0 auto 5px;
}

.noticias_bloco .noticia_block.secondary .noticia_block-button .button {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px 50px;
  border-radius: 5px;
  background: var(--secondary-color);
  height: fit-content;
  font-size: 14px;
  border: none;
  font-weight: bold;
  color: var(--text-color-light);
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
}

.noticias_bloco .noticia_block.secondary .publication-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
