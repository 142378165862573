@media (max-width: 768px) {
  .horizontalR {
    display: flex;
  }

  .horizontalR1 {
    display: flex;
    width: 50% !important;
  }
}

.horizontalBreak {
  margin-top: 50px;
  z-index: 4 !important;
}

@media (max-width: 768px) {
  .horizontalBreak {
    margin-top: -5px;
  }

  .horizontalBreak > .row {
    flex-wrap: nowrap;
    overflow-x: auto !important;
    white-space: initial !important;
    -webkit-overflow-scrolling: touch;
    -webkit-user-drag: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    cursor: grab;
  }

  .horizontalBreak > .row::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }

  .horizontalBreak > .row::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }

  .horizontalBreak > .row::-webkit-scrollbar-button {
    width: 0;
  }

  .horizontalBreak > .row > .col-md-3 {
    display: flex;
    float: none;
    flex: 0 0 auto !important;
    max-width: 300px;
  }
}

.primaryServiceRow {
  margin-bottom: 25px;
}

.destaques_bloco--titulos {
  margin-bottom: 50px;
}

.destaques_bloco--titulos h2 {
  font-weight: bold;
  font-size: 47px;
  text-align: left;
  margin: 0;
}

.destaques_bloco--titulos p {
  /* width: 430px; */
  font-weight: normal;
  /* font-size: 14px; */
  text-align: left;
  opacity: 0.5;
  font-size: 18px;
  line-height: 24px;
}

.destaques_bloco--titulos.mais-procurados h2,
.destaques_bloco--titulos.mais-procurados p {
  color: var(--text-color-light);
}
