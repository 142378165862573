.link__card {
  width: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

@media (max-width: 768px) {
  .link__card {
    margin-bottom: 25px;
  }
}

.link__card .link__card--img {
  width: 100%;
  height: 250px;
  background: var(--offwhite-light);
  overflow: hidden;
  border-radius: 5px 5px 0px 0px;
}

.link__card .link__card--img img {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px 5px 0px 0px;
}

.link__card .link__card--img--revists {
  width: 100%;
  height: 270px;
  background: var(--offwhite-light);
  overflow: hidden;
}

.link__card .link__card--img--revists img {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
}

.link__card .link__card--info--revista {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0px;
}

.link__card .link__card--info--revista .name__link {
  padding: 0;
}

.link__card .link__card--info--revista .name__link p {
  margin: 0 !important;
}

.link__card .link__card--info {
  background: var(--secondary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.link__card .link__card--info .name__link {
  padding: 10px 0px 0px 0px;
  width: 95%;
  text-align: center;
}

.link__card .link__card--info .name__link p {
  font-size: 14px;
  color: var(--text-color-dark);
  font-weight: 400;
}

.link__card .link__card--info .link__link {
  padding: 10px 0px 0px 0px;
  width: 95%;
  text-align: center;
  border-top: 1px solid var(--text-color-light);
}

.link__card .link__card--info .link__link p {
  font-size: 14px;
  color: var(--text-color-dark);
  font-weight: 400;
}
