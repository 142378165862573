.container .memorial_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 425px) {
  .container .memorial_header {
    flex-direction: column;
  }
}

.container .memorial_header .month_select {
  margin-top: -20px;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 140px;
}

@media (max-width: 425px) {
  .container .memorial_header .month_select {
    margin-top: 10px;
    margin-left: 0;
    max-width: 220px;
  }
}

.container .memorial_header .month_select select {
  margin: 0;
  width: 100%;
}

.container .empty_memorial {
  margin-top: 45px;
}
